html {
    box-sizing: border-box;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }
  
  ul,
  ol {
    padding: 0;
  }
  
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }
  
  ul {
    list-style: none;
  }
  
  img, picture, source {
    width: 100%;
    display: block;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  input,
  button,
  textarea,
  select {
    font: inherit;
    letter-spacing: inherit;
  }