$black: #000000;
$brown: #C99559;
$light-brown: #F0EAE2;
$beige: #ddd6cd;
$dark-beige: #d2b99c;
$beige-hover: #AF9F8C;
$gray: #627582;
$red: #E94235;
$white: #FFFFFF;
$pink: #E8C3DD;
$light-gray: #A4A4A4;
$background-beige: #F4F3F1;
$background-gray: #50514B;
$dark-green: #464C40;
$sky-blue: #6487B2;
$orange: #E97635;
$semi-beige: #D9BBA6;
$green: #223D26;
$blue: #2178AD;
$dark-blue: #678699;
$light-gray: #4F4F4F;
$purpl: #322B9B;
$purple2: #223C90;
$blue2: #038ABA;
$gray2: #5F858A;
$salad: #43C938;
$dark-orange: #E14A1B;
$bright-orange: #EB6300;
$dark-yellow: #C3D100;
$dark-sky: #95ABDD;
$genesis: #0C2D80;
$yellow-brown: #908A45;
$shadow: #262626;
$scalar: #1E7112;
$three: #00729E;
$gift: #002E85;
$field: #7F9129;
$map: #686B1D;
$smart: #5397FF;
$bionica: #1850A5;

