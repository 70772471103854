body {
  font-family: "GraphicLCG", sans-serif;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 400;
  font-style: normal;
}
body.lock {
  overflow: hidden;
}
.app {
  position: relative;
  overflow-x: clip;
} 
.app__lock {
  height: 100vh;
  overflow: hidden;
}

.main-page {
  // position: absolute;
  top: -15%;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: -1;

}
.cases-page {
  top: -15%;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: -1;
}
main {
  overflow: hidden;
  position: relative;
}

#cursor,
#aura {
  position: absolute;
  top: -100px;
  border-radius: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.2s ease-in-out;
  user-select: none;
  pointer-events: none;
  z-index: 1000;
}

#cursor {
  width: 8px;
  height: 8px;
  background-color: $gray;
  transform: scale(1);
  &.active {
    opacity: 0;
    transform: scale(0);
  }
}

#aura {
  width: 46px;
  height: 46px;
  border: 1px solid $gray;
  background-color: rgba($light-gray, 0.2);
  transform: translate(5px, 5px);
  &.active {
    transform: scale(1.2);
  }
}

#cursor.hidden,
#aura.hidden {
  transform: scale(0.001);
}

.scroll-lock {
  overflow: hidden;
}

.container {
  position: relative;
  max-width: 1760px;
  width: 90%;
  margin: 0 auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page-transition {
  position: absolute;
  top: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.title {
  font-family: "CraftworkGrotesk", sans-serif;
  font-weight: 700;
  font-size: 96px;
  color: $black;
}

.title-h2 {
  margin-bottom: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-weight: 600;
}

.button {
  padding: 30px 75px;
  width: auto;
  height: auto;
  border: none;
  border-radius: 50px;
  background-color: $black;
  color: $white;
  font-weight: 500;
  font-size: 24px;
  cursor: pointer;
  // transition: color backgroundolor 0.5s ease;
  &--red,
  &:hover,
  &:focus {
    color: $white;
    background-color: $red;
  }
  &--down {
    background-color: $light-gray;
    &:hover,
    &:focus {
      background-color: $black;
    }
  }
  &--header {
    font-family: "CraftworkGrotesk", sans-serif;
    font-weight: 700;
    font-size: 32px;
    background-color: $red;
    color: $white;
    padding: 30px 118px;
    transition: background-color 0.5s ease;
    &:hover,
    &:focus {
      background-color: $black;
    }
  }
}
.button--red {
  background-color: $red;
  transition: background-color 0.5s ease;
}
.button--down.button--red {
  background-color: $black;
  &:hover,
  &:focus {
    background-color: $black;
  }
}

.link {
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $red;
  }
}

@media (max-width: 1280px) {
  .button {
    &--header {
      font-size: 28px;
      padding: 30px 100px;
    }
  }
}

@media (max-width: 991px) {
  #cursor,
  #aura {
    display: none;
  }
  .title {
    font-size: 60px;
  }

  .button {
    &--header {
      padding: 30px 60px;
    }
  }
}

@media (max-width: 768px) {
  .title {
    display: none;
  }
  .button {
    &--header {
      position: relative;
      padding: 30px 28px;
      img {
        width: 20px;
        height: 16px;
      }
    }
  }
}

@media (max-width: 500px) {
  .button {
    &--header {
      padding: 23px 21px;
    }
  }
}
