@import "./../../style/vars";
.feedback-header {
  overflow-x: clip;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  overflow-y: clip;
  padding: 25px 25px 50px 65px;
  transform: translateX(100%);
  visibility: hidden;
  z-index: 10;
  background-color: #f7f7f7;
  border-left: rgba(48, 48, 48, 0.2) solid 1px;
  border-bottom: rgba(48, 48, 48, 0.2) solid 1px;
  &__active {
  }
  &__create {
    width: 50%;
    background-color: red;
    height: 100vh;
  }
  &__ring {
    display: block;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    margin-left: auto;
    padding: 0;
    background-color: #000;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    border: none;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    &:hover,
    &:focus {
      background-color: $red;
    }
  }
  &__title {
    display: none;
    margin: 40px 0;
    font-family: "CraftworkGrotesk", sans-serif;
    font-size: 24px;
    font-weight: 600;
  }
  &__label {
    margin-bottom: 35px;
    display: block;
    position: relative;
  }
  &__input {
    display: block;
    margin-bottom: 35px;
    padding: 2vh 40px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 50px;
    font-size: 36px;
    &::placeholder {
      color: #f7f7f7;
      color: rgba(48, 48, 48, 0.2);
    }
  }
  &__textarea {
    display: block;
    margin-bottom: 35px;
    padding: 30px 40px;
    width: 100%;
    height: 20vh;
    resize: none;
    border: 1px solid #000;
    border-radius: 50px;
    font-size: 36px;
    &::placeholder {
      color: #f7f7f7;
      color: rgba(48, 48, 48, 0.2);
    }
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  &__text {
    max-width: 460px;
    font-size: 16px;
    font-weight: 300;
  }
  &__btn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;
    background-color: $red;
    &:hover,
    &:focus {
      background-color: #000;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      &:hover,
      &:focus {
        background-color: $red;
      }
    }
  }
}

.error {
  position: absolute;
  top: 105%;
  left: 30px;
  font-size: 18px;
  font-style: normal;
  color: $red;
}

.create {
  overflow-x: clip;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  padding: 25px 25px 50px 65px;
  transform: translateX(100%);
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
  background-color: #f7f7f7;
  border-left: rgba(48, 48, 48, 0.2) solid 1px;
  border-bottom: rgba(48, 48, 48, 0.2) solid 1px;
  &__active {
    pointer-events: all;
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__text {
    width: 100%;
    font-size: 6.3vw;
    font-family: 'CraftworkGrotesk',  sans-serif;
    display: block;
    margin-top: 12%;
  }
}



@media (max-width: 1600px) {
  .feedback-header {
    &__title {
      margin: 30px 0;
      font-size: 48px;
    }
    &__input {
      padding: 20px 30px;
    }
    &__btn {
      padding: 30px 40px;
      width: 40%;
    }
    &__text {
      width: 56%;
    }
  }
}

@media (max-width: 1280px) {
  .feedback-header {
    overflow-y: auto;
    &__ring {
      margin-bottom: 30px;
      width: 55px;
      height: 55px;
    }
    &__title {
      font-size: 36px;
    }
    &__label {
      margin-bottom: 25px;
    }
    &__input {
      padding: 15px 20px;
      font-size: 24px;
    }
    &__textarea {
      padding: 15px 20px;
      border-radius: 25px;
    }
    .error {
      left: 20px;
    }
  }
}

@media (max-width: 768px) {
  .feedback-header {
    padding: 20px 20px 25px 20px;
    width: 100%;
    &__ring {
      margin-bottom: -10px;
    }
    &__title {
      display: block;
      font-size: 24px;
    }
    &__text {
      width: 62%;
      font-size: 12px;
    }
    &__box {
      flex-direction: column;
    }
    &__input {
      font-size: 15px;
    }
    &__textarea {
      margin-bottom: 25px;
      height: 120px;
      font-size: 15px;
    }
    &__text {
      order: 2;
      width: 100%;
      text-align: center;
      font-size: 7px;
    }
    &__btn {
      order: 1;
      width: 100%;
      padding: 25px 80px;
    }
  }
  .create {
    display: none;
  }
}

@media (max-width: 576px) {
  .feedback {
    &__title {
      font-size: 24px;
    }
    &__label {
      margin-bottom: 15px;
    }
    &__input {
      font-size: 16px;
    }
    &__btn {
      padding: 15px 10px;
    }
    &__text {
      font-size: 8px;
    }
  }
}
