.footer {
  margin-bottom: 40px;
  font-size: 20px;
  &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      border-top: 1px solid #000;
  }
  &__social {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 75px 130px;
    margin-bottom: 145px;
  }
  &__item {
    font-size: 36px;
  }

  &__privacy {
    font-size: 24px;
    color: #020202;
    text-decoration: underline;
    text-underline-offset: 0.3em;
  }
}


@media (max-width: 1280px) {
  

  .feedback {
    max-width: 590px;
    &__title {
      font-size: 36px;
    }
    &__input {
      margin-bottom: 25px;
      padding: 15px 20px;
      font-size: 24px;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    &__inner {
      flex-direction: column;
      align-items: baseline;
    }
    &__item {
      font-size: 36px;
    }
  }
}


@media (max-width: 576px) {
  .footer {
    &__right-box {
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: space-between;
    }
    &__social {
      margin-bottom: 0;
      display: block;
    }
    &__item:not(:last-child) {
      margin-bottom: 15px;
    }
    &__privacy {
      margin-bottom: 10px;
    }
  }
  
}