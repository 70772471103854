.skeleton {
  padding: 0 0 20px;
  @keyframes example {
    0%   {background-color:#C4C4C4; left:0px; top:0px;}
    25%  {background-color:#DADADA; left:15%; top:0px;}
    50%  {background-color:#C4C4C4; left:15%; top:10%;}
    75%  {background-color:#DADADA; left:10%; top:17%;}
    100% {background-color:#C4C4C4; left:0px; top:0px;}
  }
  &__header {
    width: 25%;
    height: 5vw;
    background-color: #C4C4C4;
    animation: example 8s infinite;
    border-radius: 20px;
  }
  &__block {
      height: 35vw;
      border-radius: 50px;
      width: 100%;
      animation: example 8s infinite;
      background-color: #C4C4C4;
      margin-top: 15px;
  }
}
@media(max-width: 576px) {
  .skeleton {
    &__header, &__block {
      border-radius: 20px;
    }
  }
}