@font-face {
  font-family: 'CraftworkGrotesk';
  src:
      url('./../resourses/fonts/CraftworkGrotesk-Bold.woff') format('woff'),
      url('./../resourses/fonts/CraftworkGrotesk-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CraftworkGrotesk', sans-serif;
  src:
      url('./../resourses/fonts/CraftworkGrotesk-Heavy.woff') format('woff'),
      url('./../resourses/fonts/CraftworkGrotesk-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CraftworkGrotesk', sans-serif;
  src:
    url('./../resourses/fonts/CraftworkGrotesk-Medium.woff') format('woff'),
    url('./../resourses/fonts/CraftworkGrotesk-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'CraftworkGrotesk', sans-serif;
  src:
    url('./../resourses/fonts/CraftworkGrotesk-Regular.woff') format('woff'),
    url('./../resourses/fonts/CraftworkGrotesk-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-Black.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-BlackItalic.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-Semibold.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-Medium.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-MediumItalic.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-Regular.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-RegularItalic.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-RegularItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-Light.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-LightItalic.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-Extralight.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-Extralight.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'GraphikLCG';
  src:
    url('./../resourses/fonts/GraphikLCG-ExtralightItalic.woff') format('woff'),
    url('./../resourses/fonts/GraphikLCG-ExtralightItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Abel';
  src:
    url('./../resourses/fonts/Abel-Regular.woff') format('woff'),
    url('./../resourses/fonts/Abel-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
