@import './../../style/vars';
.feedback {
  max-width: 850px;
  position: relative;
 
  &__title {
    margin: 40px 0;
    font-family: 'CraftworkGrotesk', sans-serif;
    font-size: 64px;
    font-weight: 600;
  }
  &__input {
    display: block;
    margin-bottom: 35px;
    padding: 30px 40px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 437px;
    font-size: 36px;
    &::placeholder {
      color: #F7F7F7;
      color: rgba(48, 48, 48, 0.2);
    }
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  &__text {
    max-width: 460px;
    font-size: 16px;
    font-weight: 300;
  }
  &__responce {
    position: absolute;
    width: 102%;
    height: 102%;
    border-radius: 20px;
    top: -1%;
    left: -200%;
    z-index: 1;
    background-color: rgba(204, 204, 204, 1);
    transition: all .3s ease-in-out;
    &--header {
      position: absolute;
      left: 200%;
      width: 100%;
      height: 100%;
      transition: all .8s ease-in-out;
    }
    &--header.active {
      left: 0;
      height: 100%;
    }
  }
  &__responce.active {
    position: absolute;
    width: 102%;
    height: 102%;
    border-radius: 20px;
    top: -1%;
    left: -1%;
    z-index: 1;
    background-color: rgba(204, 204, 204, 1);
    &--header.active {
      left: 0;
      height: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 27px;
    right: 27px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      circle {
        fill: $red;
        transition: all 0.5s ease-in-out;
      }
    }
    &:hover,
    &:focus {
      svg {
        circle {
          fill: #000;
        }
      }
    }
  }
  &__wrapper {
    padding: 50px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &--header {
      padding: 17vw 0 0 ;
    }
    p {
      width: 70%;
      text-align: center;
      color: #fff;
      font-size: 54px;
      margin-bottom: 25px;
    }
  }
  &__logo {
    width: 25%;
    margin: 0 auto;
    margin-bottom: 25px;
    svg {
      width: 100%;
    }
    &--small {
      width: 10%;
    }
  }
  &__btn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    color: #fff;
    background-color: $red;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover, 
    &:focus {
      background-color: #000;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
        &:hover, 
        &:focus {
          background-color: $red;
        }
    }
  }
}
.feedback__responce--header.active {
  height: 100%;
  top: 0;
  border-radius: 0;
}
.error {
  position: absolute;
  top: 38%;
  left: 105%;
  width: 150px;
  font-size: 18px;
  font-style: normal;
  color: $red;
  &--header {
    left: 4%;
    width: 260px;
    top: 105%;
  }
}

@media (max-width: 1600px) {
  .feedback {
    max-width: 690px;
    &__title {
      margin: 30px 0;
      font-size: 48px;
    }
    &__input {
      padding: 20px 30px;
    }
    &__btn {
      padding: 30px 40px;
      width: 40%;
    }
    &__text {
      width: 56%;
    }
  }
}

@media (max-width: 1280px) {

  .feedback {
    max-width: 590px;
    &__title {
      font-size: 36px;
    }
    &__input {
      margin-bottom: 25px;
      padding: 15px 20px;
      font-size: 24px;
    }
    &__box {
      flex-direction: column;
    }
    &__btn {
      width: 100%;
      order: 1;
    }
    &__text {
      width: 100%;
    }
    &__wrapper {
      p {
        font-size: 30px;
      }
    }
    &__logo {
      margin-bottom: 0;
    }
    .error {
      margin-bottom: 25px;
    }
  }
  .error {
    position: absolute;
    top: 105%;
    left: 30px;
    font-size: 18px;
    font-style: normal;
    color: $red;
  }
}

@media (max-width: 768px) {
  .feedback {
    &__btn {
      width: 100%;
      padding: 15px 30px;
      font-size: 14px;
    }
    &__text {
      width: 62%;
      font-size: 12px;
    }
  }
}

@media (max-width: 576px) {
  .feedback {
    &__title {
      font-size: 24px;
    }
    &__input {
      margin-bottom: 15px;
      font-size: 16px;
    }
    &__btn {
      padding: 15px 10px;
    }
    &__text {
      font-size: 8px;
    }
    &__close {
      top: 12px;
      right: 12px;
      svg {
        transform: scale(.5);
      }
    }
    .error {
      margin-bottom: 15px;
    }
  }
}